import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Admin from './pages/admin';
import './App.css';
import Exercise from './pages/exercise';
import Lessons from './pages/lessons';
import Layout from './pages/layout';
import LoginScreen from './pages/Login';
import Lesson from './pages/lesson';

function PrivateRoute({ element }: { element: JSX.Element}) {
  const token = localStorage.getItem('token')
  return token ? element : <Navigate replace to="/login" />;
}

function AdminRoute({ element }: { element: JSX.Element}) {
  const token = localStorage.getItem('token')
  if (!token) return <Navigate replace to="/login" />

  const isAdmin = localStorage.getItem('isAdmin')
  if (isAdmin !== 'true') return <Navigate replace to="/login" />

  return element;
}

function App() {
  
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/lessons" element={<PrivateRoute element={<Lessons />} />} />
          <Route path="/lessons/:lessonId" element={<PrivateRoute element={<Lesson />} />} />
          <Route path="/lessons/:lessonId/layouts/:layoutId" element={<PrivateRoute element={<Layout />} />} />
          <Route path="/lessons/:lessonId/layouts/:layoutId/exercises/:exerciseId" element={<PrivateRoute element={<Exercise />} />} />
          <Route path="/admin" element={<AdminRoute element={<Admin />} />} />
          <Route path="/" element={<Navigate replace to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;