import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../@/components/ui/breadcrumb';
import { Exercise } from '../@/types/exercise';
import Header from '../@/components/ui/header';

const Layout = () => {
  const { lessonId, layoutId } = useParams<{ lessonId: string; layoutId: string }>();
  const navigate = useNavigate();

  const [exercises, setExercises] = useState<Exercise[]>([]);

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/lessons/${lessonId}/layouts/${layoutId}/exercises`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.status === 401 || response.status === 403) {
          localStorage.removeItem('token');
          localStorage.removeItem('isAdmin');
          navigate('/login');
          return;
        }

        const data = await response.json();
        setExercises(data);
      } catch (error) {
        console.error('Error fetching exercises:', error);
      }
    };

    fetchExercises();
  }, [lessonId, layoutId, navigate]);

  return (
    <div className="bg-white min-h-screen p-4">
      <div className="max-w-4xl mx-auto">        
        <Header title={`Lesson ${lessonId} - Layout ${layoutId}`} />      
        <Breadcrumb lessonId={lessonId} layoutId={layoutId} />
        <button
          onClick={() => navigate(`/lessons/${lessonId}`)}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
          Back to Layouts
        </button>
      </div>

      <div className="max-w-4xl mx-auto grid grid-cols-2 gap-4 mt-6">
        {exercises.map((exercise) => (
          <Link
            to={`/lessons/${lessonId}/layouts/${layoutId}/exercises/${exercise.id}`}
            key={`${exercise.id}-${exercise.id}`}
            className="relative rounded-lg p-4 text-left bg-blue-50 text-blue-700 hover:bg-blue-100"
          >
            <div className="w-10 h-10 bg-white rounded-lg flex items-center justify-center mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M12 2a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z" />
                <path d="M19 10v2a7 7 0 0 1-14 0v-2" />
                <line x1="12" x2="12" y1="19" y2="22" />
              </svg>
            </div>
            <h2 className="font-semibold">{exercise.name}</h2>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Layout;