import React, { useEffect, useState } from 'react';
import { Button, Table, Space, Modal, Form, Input, message } from 'antd';
import axios from 'axios';
import Header from '../@/components/ui/header';

const AdminPage: React.FC = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editForm] = Form.useForm();

    const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] = useState(false);
    const [changePasswordForm] = Form.useForm();

    const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false);
    const [addUserForm] = Form.useForm();
    const [searchText, setSearchText] = useState('');

    interface User {
        id: number;
        name: string;
        login: string;
        email?: string;
        password?: string;
        isAdmin: boolean;
        contract?: string;
    }

    const [userBeingEdited, setUserBeingEdited] = useState<User | null>(null);

    const handleErrorResponse = (error: any) => {
        if (error.response && (error.response.status === 403 || error.response.status === 401)) {
            window.location.href = '/lessons';
        } else {
            message.error('An error occurred. Try again later and contact the administrator if the problem persists.');
        }
    };

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get('/api/users', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setUsers(response.data);
        } catch (error) {
            handleErrorResponse(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleEditUser = (user: User) => {
        setUserBeingEdited(user);
        editForm.setFieldsValue(user);
        setIsEditModalVisible(true);
    };

    const handleEditUserOk = async (values: any) => {
        try {
            Object.keys(values).forEach(key => {
                if (typeof values[key] === 'string') {
                    values[key] = values[key].trim();
                }
            });
            const token = localStorage.getItem('token');
            if (userBeingEdited) {
                await axios.put(`/api/users/${userBeingEdited.id}`, values, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                message.success('User updated successfully');
            } else {
                await axios.post('/api/users', values, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                message.success('User added successfully');
            }
            fetchUsers();
            setIsEditModalVisible(false);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response && error.response.status === 409) {
                message.error('Login already used by another user');
            } else {
                handleErrorResponse(error);
            }
        }
    };

    const handleChangePassword = (user: User) => {
        setUserBeingEdited(user);
        changePasswordForm.resetFields();
        setIsChangePasswordModalVisible(true);
    };

    const handleChangePasswordOk = async (values: any) => {
        try {
            Object.keys(values).forEach(key => {
                if (typeof values[key] === 'string') {
                    values[key] = values[key].trim();
                }
            });
            const token = localStorage.getItem('token');
            await axios.put(`/api/users/${userBeingEdited?.id}`, values, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            message.success('Password changed successfully');
            setIsChangePasswordModalVisible(false);
        } catch (error) {
            handleErrorResponse(error);
        }
    };

    const handleDeleteUser = async (userId: number) => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`/api/users/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            message.success('User deleted successfully');
            fetchUsers();
        } catch (error) {
            handleErrorResponse(error);
        }
    };

    const confirmDeleteUser = (user: User) => {
        Modal.confirm({
            title: 'Are you sure you want to delete user ' + user.name + '?',
            onOk: () => handleDeleteUser(user.id),
        });
    };

    const handleAddUser = () => {
        setIsAddUserModalVisible(true);
    };

    const handleAddUserOk = async (values: any) => {
        try {
            Object.keys(values).forEach(key => {
                if (typeof values[key] === 'string') {
                    values[key] = values[key].trim();
                }
            });
            const token = localStorage.getItem('token');
            await axios.post('/api/users', values, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            message.success('User added successfully');
            fetchUsers();
            setIsAddUserModalVisible(false);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response && error.response.status === 409) {
                message.error('User already exists');
            } else {
                handleErrorResponse(error);
            }
        }
    };


    const handleCancelEditUser = () => {
        setIsEditModalVisible(false);
    };
    const handleCancelChangePassword = () => {
        setIsChangePasswordModalVisible(false);
    };
    const handleCancelAddUser = () => {
        setIsAddUserModalVisible(false);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const filteredUsers = users.filter((user: User) =>
        user.name?.toLowerCase().includes(searchText.toLowerCase()) ||
        user.login?.toLowerCase().includes(searchText.toLowerCase()) ||
        user.email?.toLowerCase().includes(searchText.toLowerCase()) ||
        user.contract?.toLowerCase().includes(searchText.toLowerCase()) 
    );

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        },
        {
            title: 'Login',
            dataIndex: 'login',
            key: 'login',
            sorter: (a: any, b: any) => a.login.localeCompare(b.login),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a: any, b: any) => (a.email || '').localeCompare(b.email || ''),
        },
        {
            title: 'Admin',
            dataIndex: 'isAdmin',
            key: 'isAdmin',
            render: (isAdmin: boolean) => (isAdmin ? 'Yes' : 'No'),
            sorter: (a: any, b: any) => a.isAdmin - b.isAdmin,
        },
        {
            title: 'Contract',
            dataIndex: 'contract',
            key: 'contract',
            sorter: (a: any, b: any) => (a.contract || '').localeCompare(b.contract || ''),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (record: any) => (
                <Space size="middle">
                    <Button onClick={() => handleEditUser(record)}>Edit Info</Button>
                    <Button onClick={() => handleChangePassword(record)}>Change Password</Button>
                    <Button danger onClick={() => confirmDeleteUser(record)}>Delete</Button>
                </Space>
            ),
        },
    ];

    return (
        <div className="bg-white min-h-screen p-4">
            <div className="max-w-6xl mx-auto">
                <Header title={`Admin Panel`} />
                <section className="mt-12">
                    <div className="flex justify-between items-center mb-4">
                        <div className="flex items-center space-x-2">
                            <h2 className="text-xl font-semibold mr-5">Users</h2>
                            <Input placeholder="Search users" value={searchText} onChange={handleSearch} />
                        </div>
                        <Button type="primary" onClick={handleAddUser}>
                            Add User
                        </Button>
                    </div>
                    <Table
                        columns={columns}
                        dataSource={filteredUsers}
                        loading={loading}
                        rowKey="id"
                        pagination={{ position: ['bottomCenter'] }}
                    />
                </section>
                <Modal
                    title='Edit User'
                    open={isEditModalVisible}
                    onCancel={handleCancelEditUser}
                    footer={null}
                >
                    <Form
                        form={editForm}
                        initialValues={userBeingEdited || { name: '', login: '', email: '', password: '', isAdmin: false, contract: '' }}
                        onFinish={handleEditUserOk}
                    >
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{ required: true, message: 'Please input the name!' }]}
                        >
                            <Input onChange={(e) => editForm.setFieldsValue({ name: e.target.value })} />
                        </Form.Item>
                        <Form.Item
                            name="login"
                            label="Login"
                            rules={[
                                { required: true, message: 'Please input the login!' },
                                { pattern: /^\S*$/, message: 'Login cannot contain spaces!' }
                            ]}
                        >
                            <Input onChange={(e) => editForm.setFieldsValue({ login: e.target.value })} />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[{ type: 'email', message: 'Please enter a valid email!' }]}
                        >
                            <Input onChange={(e) => editForm.setFieldsValue({ email: e.target.value })} />
                        </Form.Item>
                        <Form.Item
                            name="contract"
                            label="Contract"
                        >
                            <Input onChange={(e) => editForm.setFieldsValue({ contract: e.target.value })} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                {'Update'}
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title="Add User"
                    open={isAddUserModalVisible}
                    onCancel={handleCancelAddUser}
                    footer={null}
                >
                    <Form
                        form={addUserForm}
                        initialValues={{ name: '', login: '', email: '', password: '', isAdmin: false, contract: '' }}
                        onFinish={handleAddUserOk}
                    >
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{ required: true, message: 'Please input the name!' }]}
                        >
                            <Input onChange={(e) => addUserForm.setFieldsValue({ name: e.target.value })} />
                        </Form.Item>
                        <Form.Item
                            name="login"
                            label="Login"
                            rules={[
                                { required: true, message: 'Please input the login!' },
                                { pattern: /^\S*$/, message: 'Login cannot contain spaces!' }
                            ]}
                        >
                            <Input onChange={(e) => addUserForm.setFieldsValue({ login: e.target.value })} />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[{ type: 'email', message: 'Please enter a valid email!' }]}
                        >
                            <Input onChange={(e) => addUserForm.setFieldsValue({ email: e.target.value })} />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[{ required: true, message: 'Please input the password!' }]}
                        >
                            <Input.Password onChange={(e) => addUserForm.setFieldsValue({ password: e.target.value })} />
                        </Form.Item>
                        <Form.Item
                            name="contract"
                            label="Contract"
                        >
                            <Input onChange={(e) => addUserForm.setFieldsValue({ contract: e.target.value })} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Add
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title="Change Password"
                    open={isChangePasswordModalVisible}
                    onCancel={handleCancelChangePassword}
                    footer={null}
                >
                    <Form
                        form={changePasswordForm}
                        onFinish={handleChangePasswordOk}
                    >
                        <Form.Item
                            name="password"
                            label="New Password"
                            rules={[{ required: true, message: 'Please input the new password!' }]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Change Password
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </div>
    );
};

export default AdminPage;
