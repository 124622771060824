import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../@/components/ui/header'; // Adjust the import path as necessary

const Lessons = () => {
  interface Lesson {
    lessonId: string;
    name: string;
  }

  const [lessons, setLessons] = useState<Lesson[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLessons = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('/api/lessons', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (response.status === 401 || response.status === 403) {
          localStorage.removeItem('token');
          navigate('/login');
          return;
        }

        const data = await response.json();
        setLessons(data);
      } catch (error) {
        console.error('Error fetching lessons:', error);
      }
    };

    fetchLessons();
  }, [navigate]);

  return (
    <div className="bg-white min-h-screen p-4">
      <div className="max-w-4xl mx-auto">
        <Header title="Lessons"/>

        <div className="max-w-4xl mx-auto grid grid-cols-2 gap-4 mt-6">
          {lessons.map((lesson) => (
            <Link 
              to={`/lessons/${lesson.lessonId}`} 
              key={lesson.lessonId}
              className="relative rounded-lg p-4 bg-blue-50 text-blue-600 hover:bg-blue-100"
            >
              <h2 className="font-semibold mb-2">{lesson.name}</h2>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Lessons;
