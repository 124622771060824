import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface HeaderProps {
    title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isAdmin = localStorage.getItem('isAdmin') === 'true';

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('isAdmin');
        navigate('/login');
    };

    const handleAdminPanel = () => {
        if (location.pathname === '/admin') {
            navigate('/lessons');
        } else {
            navigate('/admin');
        }
    };

    return (
        <header className="flex justify-between items-center mb-2">
            <h1 className="text-2xl font-bold text-blue-600">{title}</h1>
            <div>
                {isAdmin && (
                    <button 
                        onClick={handleAdminPanel} 
                        className="bg-blue-600 text-white rounded px-4 py-2 mr-4 hover:bg-blue-800"
                    >
                        {location.pathname === '/admin' ? 'Go to Lessons' : 'Admin Panel'}
                    </button>
                )}
                <button 
                    onClick={handleLogout} 
                    className="text-blue-600 hover:text-blue-800"
                >
                    Logout
                </button>
            </div>
        </header>
    );
};

export default Header;