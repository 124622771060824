import { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Breadcrumb from '../@/components/ui/breadcrumb';
import { Exercise } from '../@/types/exercise';
import Header from '../@/components/ui/header';

const ExercisePage = () => {
  const { lessonId, layoutId, exerciseId } = useParams<{ lessonId: string; layoutId: string; exerciseId: string }>();
  const navigate = useNavigate();
  const [exercise, setExercise] = useState<Exercise | null>(null);
  const [exercises, setExercises] = useState<Exercise[]>([]);
  const [currentClipIndex, setCurrentClipIndex] = useState(0);
  const [controlsVisible, setControlsVisible] = useState(false);
  const [title, setTitle] = useState(`Lesson ${lessonId} - Layout ${layoutId} - Exercise ${exerciseId}`);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/lessons/${lessonId}/layouts/${layoutId}/exercises`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.status === 401 || response.status === 403) {
          localStorage.removeItem('token');
          localStorage.removeItem('isAdmin');
          navigate('/login');
          return;
        }

        const data = await response.json();
        setExercises(data);
        const currentExercise = data.find((ex: any) => ex.id === parseInt(exerciseId || '0'));
        setExercise(currentExercise || null);
      } catch (error) {
        console.error('Error fetching exercises:', error);
      }
    };

    fetchExercises();
  }, [lessonId, layoutId, exerciseId, navigate]);

  useEffect(() => {
    if (controlsVisible && audioRef.current) {
      const audioElement = audioRef.current;
      audioElement.pause();
      audioElement.currentTime = 0;
      audioElement.play().catch(error => {
        console.error('Error playing audio:', error);
      });
    }
  }, [currentClipIndex, controlsVisible]);

  const handlePreviousClip = () => {
    setCurrentClipIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const handleNextClip = () => {
    setCurrentClipIndex((prevIndex) => (exercise && prevIndex < exercise.clips.length - 1 ? prevIndex + 1 : prevIndex));
  };

  const handlePlayButtonClick = () => {
    setControlsVisible(true);
  };

  const handleExerciseClick = (ex: Exercise) => {
    setExercise(ex);
    setCurrentClipIndex(0);
    setControlsVisible(false);
    setTitle(`Lesson ${lessonId} - Layout ${layoutId} - Exercise ${ex.id}`);
  };

  if (!exercise) {
    return <div>Loading...</div>;
  }

  const currentClip = exercise.clips[currentClipIndex];

  return (
    <div className="bg-white min-h-screen p-4 flex justify-center">
      <div className="max-w-4xl w-full">
        
        <Header title={title} />  
        <Breadcrumb lessonId={lessonId} layoutId={layoutId} exerciseId={exerciseId} />
        <button
          onClick={() => navigate(`/lessons/${lessonId}/layouts/${layoutId}`)}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
          Back to Exercises
        </button>

        <div className="flex">
          {/* Sidebar */}
          <div className="w-1/5 p-4 border-gray-200 hidden md:block">
            <h2 className="text-xl font-bold mb-4 text-blue-600">Exercises</h2>
            <ul>
              {exercises.map((ex) => (
                <li key={ex.id} className="mb-2">
                  <button
                    onClick={() => handleExerciseClick(ex)}
                    className={`block p-2 rounded text-blue-600 ${ex.id === exercise.id ? 'bg-blue-100' : 'hover:bg-gray-100'}`}
                  >
                    {ex.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          {/* Main Content */}
            <div className="w-full md:w-4/5 p-4 relative">
            <div className="flex justify-center items-center relative">
              <button 
              onClick={handlePreviousClip} 
              className={`absolute left-0 p-2 rounded-full mr-4 z-10 ${currentClipIndex > 0 ? 'bg-gray-100 hover:bg-gray-200' : 'bg-gray-100 cursor-not-allowed opacity-50'}`} 
              disabled={currentClipIndex === 0}
              style={{ marginLeft: '10px', border: '2px solid black' }}
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-blue-600">
              <path d="M15 18l-6-6 6-6"/>
              </svg>
              </button>

              <div className="flex flex-col items-center relative">
              <img 
              src={`https://storage.googleapis.com/lps-static-assets-vpourdesign/${currentClip.picture}`}
              alt={`Clip ${currentClip.clipId}`} 
              className="max-w-full object-cover mb-2"
              style={{ margin: '0 10px' }}
              />
              {!controlsVisible && (
              <button onClick={handlePlayButtonClick} className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-2xl">
              <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" fill="white" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <polygon points="5 3 19 12 5 21 5 3"/>
              </svg>
              </button>
              )}
              {controlsVisible && (
              <audio key={currentClip.sound} ref={audioRef} controls>
              <source src={`https://storage.googleapis.com/lps-static-assets-vpourdesign/${currentClip.sound}`} type="audio/mpeg" />
              Your browser does not support the audio element. Use Chrome for the best experience.
              <a href={`https://storage.googleapis.com/lps-static-assets-vpourdesign/${currentClip.sound}`} download>Download audio</a>
              </audio>
              )}
              </div>

              <button 
              onClick={handleNextClip} 
              className={`absolute right-0 p-2 rounded-full ml-4 z-10 ${exercise && currentClipIndex < exercise.clips.length - 1 ? 'bg-gray-100 hover:bg-gray-200' : 'bg-gray-100 cursor-not-allowed opacity-50'}`} 
              disabled={exercise && currentClipIndex >= exercise.clips.length - 1}
              style={{ marginRight: '10px', border: '2px solid black' }}
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-blue-600">
              <path d="M9 18l6-6-6-6"/>
              </svg>
              </button>
            </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default ExercisePage;