import React from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbProps {
    lessonId?: string;
    layoutId?: string;
    exerciseId?: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ lessonId, layoutId, exerciseId }) => {
    return (
        <nav className="mb-4">
            <ul className="flex space-x-2">
                <li>
                    {!lessonId ? (
                        <span className="text-gray-500">Lessons</span>
                    ) : (
                        <Link 
                            to={{ pathname: "/lessons" }}
                            className="text-blue-600 hover:underline"
                        >
                            Lessons
                        </Link>
                    )}
                </li>
                {lessonId && (
                    <>
                        <li>
                            <span className="text-gray-500">-&gt;</span>
                        </li>
                        <li>
                            {!layoutId ? (
                                <span className="text-gray-500">Lesson {lessonId}</span>
                            ) : (
                                <Link 
                                    to={{ pathname: `/lessons/${lessonId}` }} 
                                    state={{ lessonId }}
                                    className="text-blue-600 hover:underline"
                                >
                                    Lesson {lessonId}
                                </Link>
                            )}
                        </li>
                    </>
                )}
                {layoutId && (
                    <>
                        <li>
                            <span className="text-gray-500">-&gt;</span>
                        </li>
                        <li>
                            {!exerciseId ? (
                                <span className="text-gray-500">Layout {layoutId}</span>
                            ) : (
                                <Link 
                                    to={{ pathname: `/lessons/${lessonId}/layouts/${layoutId}` }} 
                                    state={{ layoutId }}
                                    className="text-blue-600 hover:underline"
                                >
                                    Layout {layoutId}
                                </Link>
                            )}
                        </li>
                    </>
                )}
                {exerciseId && (
                    <>
                        <li>
                            <span className="text-gray-500">-&gt;</span>
                        </li>
                        <li className="text-gray-500">Exercise {exerciseId}</li>
                    </>
                )}
            </ul>
        </nav>
    );
};

export default Breadcrumb;
