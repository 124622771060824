import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../@/components/ui/breadcrumb';
import Header from '../@/components/ui/header';

const Lesson = () => {
  const { lessonId } = useParams<{ lessonId: string }>();
  const navigate = useNavigate();
  
  interface Layout {
    id: number;
    layoutId: number;
    name: string;
  }

  const [lessonLayouts, setLessonLayouts] = useState<Layout[]>([]);

  useEffect(() => {
    const fetchLayouts = async () => {
      try {
        console.log("Fetching layouts")
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/lessons/${lessonId}/layouts/`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (response.status === 401 || response.status === 403) {
          localStorage.removeItem('token');
          navigate('/login');
          return;
        }

        const data = await response.json();
        setLessonLayouts(data);
      } catch (error) {
        console.error('Error fetching lesson layouts:', error);
      }
    };

    fetchLayouts();
  }, [lessonId, navigate]);

  return (
    <div className="bg-white min-h-screen p-4">
      <div className="max-w-4xl mx-auto">
        <Header title={`Lesson ${lessonId}`} />      
        <Breadcrumb lessonId={lessonId} />
        <button
          onClick={() => navigate('/lessons')}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
          Back to Lessons
        </button>
      </div>

      <div className="max-w-4xl mx-auto grid grid-cols-2 gap-4 mt-6">
        {lessonLayouts.map((layout) => (
          <Link
            to={`/lessons/${lessonId}/layouts/${layout.layoutId}`}
            state={{ lessonId, layoutId: layout.layoutId }}
            key={layout.id}
            className={`
              relative rounded-lg p-4 text-left 
              bg-blue-50 text-blue-700 hover:bg-blue-100
            `}
          >
            <div className="w-10 h-10 bg-white rounded-lg flex items-center justify-center mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path>
                <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path>
              </svg>
            </div>
            <h2 className="font-semibold">{layout.name}</h2>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Lesson;